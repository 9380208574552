html{
  /* background-color: rgb(207, 194, 170); */
  font-family: serif;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h3{
    font-size: medium;
    font-family: system-ui;
    color: rgb(118, 145, 163);
}

footer{
    margin-top: 20px;
    min-height: 60px;
    border-top: 7px solid rgb(118, 145, 163);
    /* background: rgb(238, 227, 203); */
}

label{
    font-family: system-ui;
    color: black;
    font-weight: 900;
}

h2{
    margin: 5px 2px;
    font-family: serif;
}

button{
    font-weight: bold;
    width: auto;
    min-width: 300px;
    margin: auto;
    align-items: center;
    background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    justify-content: center;
    line-height: 1em;
    font-size: 11px;
    padding: 19px 13px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}

p{
    margin: 5px 0;
}

nav{
    font-size: 11px;
}